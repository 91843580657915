import React from 'react';
import '../styles/subcatcard.css';
import { Link } from "react-router-dom";
import { Card, Space, Layout, Button, message } from 'antd';

const SubCatCard = ({allSubCategories}) => {
  const subCatArray = Array.from(allSubCategories);

  const itemStr = localStorage.getItem('isLogin');
  const item = JSON.parse(itemStr)
  const now = new Date()

  if(!itemStr || item.expiry < now.getTime()){
    localStorage.clear();
    
  }

const success = () => {
  message.warning('You need to Log in or Sign up to get Started!', 3);
};

return (
  <>
     <Layout className='subcat-card-div'> 
           <Space size={[16, 32]} wrap>
    {subCatArray.map((curSubCat, key) => {

    return (
        
        
  
        <Card id='subcat-card' style={{ width: 350 }}
        title={curSubCat.SubCatName + ' Quiz'} bordered={false}>

<Button className='quiz-action-btn subcat-btn'>
      {(itemStr) ? 
      <Link to={{
        pathname: `/free-online-quiz/${curSubCat.SubCatName.replaceAll(" ","-")}`,   
    }} state={{ type: "subcategory-test" }} type="primary"><span className='quiz-btn'>Take Quiz</span></Link>
    : <span onClick={success} className='quiz-btn'>Take Quiz</span>}
    
    </Button>
          
          {/* <Button className=' quiz-action-btn'>Take Quiz</Button> */}
          <p className='subcat-quiz-desc'>A quiz that uncovers your knowledge about {curSubCat.SubCatName}.</p>
   
        </Card>
        )
  })}
      </Space>
     
    </Layout>
 

     </>   
    
                 
   
)
}


export default SubCatCard
