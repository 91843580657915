import React from 'react';
import { MetaTags } from 'react-meta-tags';
import "../styles/faqspage.css";
import Navbar from '../components/Navbar';
import BottomFooter from '../components/BottomFooter';
import { Collapse } from 'antd';
import {Row, Col} from 'antd';
import '../styles/faqspage.css';
import { Typography, Divider } from 'antd';


const FaqsPage = () => {

    const { Panel } = Collapse;
    const {Title, Text} = Typography;

  return (
      <>
<MetaTags>
            <title>Frequently Asked Questions - PolyQuizz</title>
            <meta id="meta-description" name="description" />          
</MetaTags>

      <Navbar />
     
      <Row className='about-us-div'>

    
<Col className='page-content-col'>
<Typography>
       

       <div className='signup-heading'>
        <Title level={3}>Frequently Asked Questions</Title>
        </div>
        <Divider />

    
      <Collapse accordion>
    <Panel header="Can I attempt a test multiple times?" key="1">
      <p>A test can be attempted as many times as you want.</p>
    </Panel>
    <Panel header="Will I get my performance analysis and solutions after the test?" key="2">
      <p>Yes, you will get a detailed analysis of your performance along with correct answers after 
      submitting the test.</p>
    </Panel>
    <Panel header="Can I download the online test with solutions after the test?" key="3">
      <p> No, you cannot download the test; however, you can access and analyse your performance in 
      recently attempted tests by logging into your account.</p>
    </Panel>
    <Panel header="When I re-attempt the test of same topic, are the questions same as previous test?" key="4">
      <p>No, You will get unique set of questions every time you attempt a test. However, some questions 
      can repeat after many number of attempts.</p>
    </Panel>
    <Panel header="How my rank for a particulat test is calculated?" key="5">
      <p>Your rank is calculated by comparing your score with other aspirants who attempt the 
      same test.</p>
    </Panel>
    <Panel header="Why do I need to sign-up to take a test?" key="6">
      <p>We require you to sign-up before attempting a online test because that helps us to provide you
      a better experience by storing your results and providing key points regarding your score improvement.</p>
    </Panel>
    <Panel header="What happens if my test is interrupted for any reason?" key="7">
      <p>If you close the browser while test is in progress or you lost Internet connectivity, all 
      your progress will be lost and you will not be able to retrieve/resume the test.</p>
    </Panel>
    
  </Collapse>

  <div className='signup-heading login-heading'>
<Text>Can't find your answers? Contact us at </Text><Text type='danger'>contact@polyquizz.com</Text>
                    
 </div>
  </Typography>

</Col>

</Row>



    
            
          

<BottomFooter />

      </>
  )
};

export default FaqsPage;
