import {React, useEffect} from 'react';
import axios from 'axios';
import { useNavigate, useLocation, useParams } from "react-router-dom";

const UserActivationPage = (props) => {


    let navigate = useNavigate();
    let location = useLocation();

    let { code } = useParams(); 
    var item ="";

    const itemStr = localStorage.getItem('isLogin');
    item = JSON.parse(itemStr)
    const now = new Date()

     if (itemStr && item.expiry > now.getTime()) {
      navigate('/');
      window.location.reload(false);
    }

    const VerifyUserAccount = () => {
        try {
           const json = JSON.stringify({activationcode : code});
           const headers = { 
               'Content-Type': 'application/json'
           };
          
           const res = axios.post('https://epolylearning.com/Services/polyquiz.svc/VerifyAccount', json, {headers}).then(response => {
                const resMsg = response.data;
                 if(resMsg.VerifyAccountResult == "Account activation succesful."){
                    navigate(
                     '/login',{
                        state: 'activationSuccess'
                    });
                 }
                 else if(resMsg.VerifyAccountResult == "Invalid Request."){
                    navigate(
                        '/login',{
                        state: 'invalidRequest'
                    });
                 }
           });
       } catch (error) {
           console.log(error);
       } 
      }

      useEffect(() => {
        VerifyUserAccount()
      },[])

  return (
  <>
  
  </>
  )
};

export default UserActivationPage;
