import React from 'react';
import {Link} from 'react-router-dom'; 
import '../styles/bottomfooter.css';
import { Typography } from 'antd';
import logo from '../images/footer_logo.png';
import { MailOutlined } from '@ant-design/icons';

const BottomFooter = () => {

    const { Title } = Typography;
  return (
    <>
    
<footer class="footer">
<div class="l-footer">

<img 
height='60px'
width='80'
src={logo} 
alt=""/>
<Title level={2}>Poly Quizz</Title>
</div>
<ul class="r-footer">
<li>
  {/* <h2>
Social</h2> */}
<ul class="box">
<li><Link className='footer-links' to ='/'>Home</Link></li>
<li><Link className='footer-links' to ='/privacy-policy'>Privacy Policy</Link></li>
<li><Link className='footer-links' to ='/frequently-asked-questions'>FAQs</Link></li>
<li><Link className='footer-links' to ='/about'>About us</Link></li>
</ul>
</li>

 

<li>
  {/* <h2>
Legal</h2> */}
<ul class="box">
<li><span><MailOutlined /> : contact@polyquizz.com</span></li>
</ul>
</li>
</ul>
<div class="b-footer">
<p>
All rights reserved ©PolyQuizz 2022 </p>
</div>
</footer>



    
    </>
  )
}

export default BottomFooter