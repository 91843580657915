import React from 'react';
import { useState, useEffect } from "react";
import axios from 'axios';
import { MetaTags } from 'react-meta-tags';
import { useNavigate, Navigate } from 'react-router-dom';
import '../styles/loginpage.css';
import Logo from '../images/logo_transparent.png'
import Navbar from '../components/Navbar';
import BottomFooter from '../components/BottomFooter';
import {Link} from 'react-router-dom';
import { Form, Input, Button,Typography } from 'antd';

const ChangePasswordPage = () => {

    const [changepasserror, setChangePassError] = useState(false);
    const [changepassmessage, setChangePassMessage] = useState(false);
    const navigate = useNavigate();
    var item ="";

    const itemStr = localStorage.getItem('isLogin');
    item = JSON.parse(itemStr)
    const now = new Date()

    const [form] = Form.useForm();
    const { Title } = Typography;

    

    function Logout() {
        localStorage.removeItem('isLogin')
      }

    const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      };

      const tailFormItemLayout = {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 8,
          },
        },
      };


    function Logout() {
        localStorage.removeItem('isLogin')
      }

  function onFinish(data) {
    setChangePassError(false);
    setChangePassMessage(false);
    try {
       const json = JSON.stringify({userid: item.userid, curpass:data.password, newpass:data.newpassword });

       const headers = { 
           'Content-Type': 'application/json'
       };
      
       const res = axios.post('https://epolylearning.com/Services/polyquiz.svc/ChangeUserPassword', json, {headers}).then(response => {
           const userData = response.data;
           console.log(response.data.ChangeUserPasswordResult);
            
             if(userData.ChangeUserPasswordResult == "Password changed successfully."){
                form.resetFields();
                setChangePassMessage(true);
                Logout();
             }
             else if(userData.ChangeUserPasswordResult == "Current password is Wrong." ){
                setChangePassError(true);
             }
           
       });
   } catch (error) {
       console.log(error);
   } 
  }

  useEffect(() => {

    if (!itemStr || item.expiry < now.getTime()) {
      navigate('/login');
     
    }   
          
    },[])

    return (
        <>
<MetaTags>
            <title>Change User Password - PolyQuiz</title>
            <meta id="meta-description" name="description" />           
</MetaTags>

        <Navbar />
        <div className='BoxContainer'>
          <div className='FormContainer change-pass-div'>

          <div className='signup-heading'>
  <img width="150px" height="100px" src={Logo} alt="Test and Rank logo" />
  </div>

    <div className='login-heading'>
    <Title className='signup-heading' level={4}>CHANGE PASSWORD</Title>
  </div>

        <div className="Login">
        <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={{
        residence: ['zhejiang', 'hangzhou', 'xihu'],
        prefix: '86',
      }}
      scrollToFirstError
    >


      <Form.Item
        name="password"
        label="Current Password"
        rules={[
          {
            required: true,
            message: 'Please input your current password!',
          },
          {
              min: 8,
              message: 'Minimum password length is 8 characters.'
          },
          {
              max:16,
              message: 'Maximum password length is 16 characters.'
          }
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="newpassword"
        label="New Password"
        type = "password"
        rules={[
          {
            required: true,
            message: 'Please input your new password!',
          },
          {
              min: 8,
              message: 'Minimum password length is 8 characters.'
          },
          {
              max:16,
              message: 'Maximum password length is 16 characters.'
          }
        ]}
        hasFeedback
      >
        <Input type="password"/>
      </Form.Item>

      <Form.Item
      className='confirm-pass'
        name="confirm"
        label="Confirm New Password"
        dependencies={['newpassword']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newpassword') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The two passwords that you entered do not match!'));
            },
          }),
        ]}
      >
        <Input type="password"/>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          Change Password
        </Button>
      </Form.Item>

        </Form> 
          </div>

{changepasserror ?<div className="invalid-user">Current Password is wrong. Please try again.</div> : ''}

{changepassmessage ?<div className="invalid-user">Password changed successfully. <Link to="/login">Click here</Link> to Log in and continue.</div> : ''}
 
          </div>
        </div>

        <BottomFooter />
        </>
      );
}

export default ChangePasswordPage
