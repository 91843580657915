import React from 'react';
import { useState, useEffect } from "react";
import axios from 'axios';
import MetaTags from 'react-meta-tags';
import { useNavigate } from "react-router-dom";
import '../styles/loginpage.css';
import Logo from '../images/logo_transparent.png';
import { Typography } from 'antd';
import {Link} from 'react-router-dom';
import Navbar from '../components/Navbar';
import BottomFooter from '../components/BottomFooter';
import { Form, Input, Button } from 'antd';

const SignupPage = () => {

    // const [passwordShown, setPasswordShown] = useState(false);
    const [signuperror, setSignUpError] = useState(false);
    const [signupmessage, setSignUpMessage] = useState(false);

    var item ="";
    const navigate = useNavigate();

    const itemStr = localStorage.getItem('isLogin');
    item = JSON.parse(itemStr)
    const now = new Date()

    const { Title } = Typography;


    const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      };

      const tailFormItemLayout = {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 8,
          },
        },
      };

      
        const [form] = Form.useForm();

      function onFinish(data) {
          setSignUpError(false);
          setSignUpMessage(false);
          try {
             const json = JSON.stringify({fname:data.firstname, lname:data.lastname, uname:data.username, email: data.email, pass: data.password });
             const headers = { 
                 'Content-Type': 'application/json'
             };
            
             const res = axios.post('https://epolylearning.com/Services/polyquiz.svc/SubmitUserData', json, {headers}).then(response => {
                 const userData = response.data;
                if(userData.SubmitUserDataResult.Firstname == "Email id already exist" && userData.SubmitUserDataResult.Lastname == "Signup Failed" ){
                     setSignUpError(true);
                   }
                   else if(userData.SubmitUserDataResult.Firstname == "User Registered Successfully" && userData.SubmitUserDataResult.Lastname == "Signup Successful" ){
                     setSignUpMessage(true);
                    form.resetFields();
                   }  
             });
         } catch (error) {
             console.log(error);
         } 
        }

        useEffect( () => {

          if (itemStr && item.expiry > now.getTime()) {
            navigate('/');
          }
  
        }, [])

    return ( 
        <>
<MetaTags>
            <title>Register for a new Account - PolyQuiz</title>
            <meta id="meta-description" name="description" 
             />      
</MetaTags>

    <Navbar />
        <div className='BoxContainer'>
        <div className='FormContainer'>
        <div className='signup-heading'>
  <img width="150px" height="100px" src={Logo} alt="Test and Rank logo" />
  </div>

  <div className='login-heading'>
    <Title className='signup-heading' level={2}>SIGN UP</Title>
  </div>

      <div className="Login">
      <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={{
        residence: ['zhejiang', 'hangzhou', 'xihu'],
        prefix: '86',
      }}
      scrollToFirstError
    >

      <Form.Item
        name="firstname"
        label="First Name"
        // tooltip="What do you want others to call you?"
        rules={[
            { required: true, message: 'Please input your First Name!'},
        { max:16, message: 'Maximum of 16 characters allowed.'},
        { whitespace: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="lastname"
        label="Last Name"
        // tooltip="Your Last Name?"
        rules={[
            { required: false},
        { max:16, message: 'Maximum of 16 characters allowed.'},
        { whitespace: true }]}
      >
        <Input />
      </Form.Item>   

      <Form.Item
        name="username"
        label="User Name"
        // tooltip="Choose a unique User Name for yourself."
        rules={[
            { required: true, message: 'Please Choose a Username!'},
        { max:16, message: 'Maximum of 16 characters allowed.'},
        { whitespace: false }]}
      >
        <Input />
      </Form.Item>  

      <Form.Item
        name="email"
        label="E-mail"
        tooltip="E-Mail verification required."
        rules={[
          {
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
          {
            required: true,
            message: 'Please input your E-mail!',
          },
        ]}
      >
        <Input />
      </Form.Item> 


      <Form.Item
        name="password"
        label="Password"
        tooltip="Minumum of 8 characters."
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
          {
              min: 8,
              message: 'Minimum password length is 8 characters.'
          },
          {
              max:16,
              message: 'Maximum password length is 16 characters.'
          }
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
      className='confirm-pass'
        name="confirm"
        label="Confirm Password"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The two passwords that you entered do not match!'));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item className='sign-up-btn' {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          Register
        </Button>
      </Form.Item>

        </Form> 
        </div>

        {signuperror ?<div className="invalid-user">This Email Id is already registered.</div> : ''}

        {signupmessage ?<div className="invalid-user">Registration Successful. Please check your Email for the Activation Link to activate your account.</div> : ''}
        
        <div>
        <span className='login-text signup-heading'>Already have an account? <Link to="/login">Log In</Link></span>
        </div>

        </div>
      </div>

      <BottomFooter />
      </>
    )
}

export default SignupPage
