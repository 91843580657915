import {React} from 'react';
import HomePageCard from './HomePageCard';
import axios from 'axios';
import {Row, Col} from 'antd';
// import categoryData from './CategoryData';
import '../styles/catcardcontainer.css';


const CatCardContainer = ({allCategories}) => {

    return (
        <>
        <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
        <div id="homepage-container">
       <HomePageCard allCategories = {allCategories} />
       </div>
       </Col></Row>
       </>
    )
}

export default CatCardContainer
