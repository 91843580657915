import React, { useEffect } from 'react';
import { useState } from "react";
import axios from 'axios';
import MetaTags from 'react-meta-tags';
import { useNavigate, useLocation } from "react-router-dom";
import '../styles/loginpage.css';
import Logo from '../images/logo_transparent.png'
import BottomFooter from '../components/BottomFooter';
import {Link} from 'react-router-dom';
import Navbar from '../components/Navbar';
import { Form, Input, Button, Typography } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const LoginPage = (props) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordShown, setPasswordShown] = useState(false);
    const [loginerror, setLoginError] = useState(false);
    const [activationMsg, setActivationMsg] = useState(false);
    const [inactiveMsg, setInactiveMsg] = useState(false);

    var item ="";
    const navigate = useNavigate();
    const location = useLocation();

    const { Title } = Typography;

    const itemStr = localStorage.getItem('isLogin');
    item = JSON.parse(itemStr)
    const now = new Date()

    const checkActivation = () => {
    if(location.state == 'activationSuccess'){
      setActivationMsg(true);
    }
  }

    function setUser(key, email, username, firstname, ttl) {
      const now = new Date()
      const item = {
        userid: key,
        email: email,
        username: username,
        firstname: firstname,
        expiry: now.getTime() + ttl,
      }
      localStorage.setItem('isLogin', JSON.stringify(item)) 
      return (navigate('/'))
    }

      const togglePassword = () => {
        setPasswordShown(!passwordShown);
      };

      function onFinish(data) {

        setLoginError(false);
        location.state = "";

          try {
             const json = JSON.stringify({ email: data.email, pass: data.password });
 
             const headers = { 
                 'Content-Type': 'application/json'
             };
            
             const res = axios.post('https://epolylearning.com/Services/polyquiz.svc/GetUserData', json, {headers}).then(response => {
                 const userData = response.data;
                 if(userData.GetUserDataResult.LoginList[0] != undefined && userData.GetUserDataResult.LoginList[0].IsActivated == false){
                   setInactiveMsg(true);
                 }
                  
                 else if(userData.GetUserDataResult.LoginList[0] != undefined && userData.GetUserDataResult.LoginList[0].IsActivated == true){
                    {setUser(userData.GetUserDataResult.LoginList[0].UserId, userData.GetUserDataResult.LoginList[0].Email, userData.GetUserDataResult.LoginList[0].UserName, userData.GetUserDataResult.LoginList[0].FirstName, 2592000000000)}
                    navigate({
                      pathname: '/',
                  });
                  
                  }
                  else{
                    setLoginError(true);
                  }
             });
         } catch (error) {
             console.log(error);
         }
      }

      useEffect( () => {

        if (itemStr && item.expiry > now.getTime() && location.state != 'activationSuccess') {
          navigate('/');
        }

        checkActivation()
      }, [])
    
      return (
        <>

<MetaTags>
            <title>Login to your Account - PolyQuiz</title>
            <meta id="meta-description" name="description" 
             />         
</MetaTags>

        <Navbar />

        <div className='BoxContainer'>
          <div className='FormContainer'>

          <div className='signup-heading'>
  <img width="150px" height="100px" src={Logo} alt="Test and Rank logo" />
  </div>

{ location.state == 'activationSuccess' ? 
    <div className='activation-msg'>
      <p>Congratulations !!! Your account has been successfully verified. You can Login now.</p>
    </div>
    :""
    }

{ location.state == 'invalidRequest' ? 
    <div className='activation-msg'>
      <p>Either this account is already verified or Invalid request.</p>
    </div>
    :""
    }    

<div className='login-heading'>
    <Title className='signup-heading' level={2}>LOG IN</Title>
  </div>

        <div className="Login">
<Form
      name="normal_login"
      
      className="login-form"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
      <Form.Item
        name="email"
        rules={[
          {
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
          {
            required: true,
            message: 'Please input your E-mail!',
          },
        ]}
      >
        <Input  style={{ width: 280 }} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your Password!',
          },
        ]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder="Password"
          style={{ width: 280 }}
          iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          Log in
        </Button>
        
      </Form.Item>
    </Form>


          </div>

          {loginerror ?<div className="invalid-user">Invalid Email or Password. Try Again.</div> : ''}

          {inactiveMsg ?<div className="invalid-user">This account is not verified yet. Please check email used at the time of registration for activation mail.
          If you are unable to find the activation mail, <Link to="/auth/resend-activation-mail">click here </Link>click here to resend the email.
          </div> : ''}

          <div className='signup-heading'>
          <Link class="nav-link" to="/auth/reset-forgot-password">Forgot Password?</Link>

          <div className='signup-heading'>
          <span className='login-text login-heading'>Don't have an account?  <Link to="/register">Sign Up</Link></span>
          </div>
          </div>
          </div>
        </div>

        <BottomFooter />

        </>
      );
}

export default LoginPage
