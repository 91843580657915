import {React, useEffect, useState} from 'react';
import MetaTags from 'react-meta-tags';
import axios from 'axios';
import { useParams, useLocation } from "react-router-dom";
import '../styles/onlinetestpage.css';
import TestInstructions from '../components/TestInstructions';
import TestConsole from '../components/TestConsole';
import GeneralInstructions from '../components/GeneralInstructions';
import {useTimer} from 'react-timer-hook';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { Row, Col, Typography, Button } from 'antd';

const OnlineTestPage = (props) => {

    const [state, setState] = useState("gen");
    const [quesData, setQuesData] = useState({});
    const [timeExpired, setTimeExpired] = useState(0);
    const [rankArray, setRankArray] = useState([]);
    const filteredScoreArray = [];

    let params = useParams();

    const location = useLocation();

    const id = params.id.replaceAll("-"," ");

    const { Text, Title } = Typography;
    let type = "";
    // console.log(type);

    if(location.state){
        type = location.state.type;
    }

    const navigate = useNavigate();
    var item ="";

    const itemStr = localStorage.getItem('isLogin');
    item = JSON.parse(itemStr)
	  const now = new Date()

    if (!itemStr || item.expiry < now.getTime()) {
      navigate('/login');
    }
    
    const testId = Math.random().toString(36).slice(2);

    function MyTimer({ expiryTimestamp }) {
        const {
          seconds,
          minutes,
          hours,
          days,
          isRunning,
          start,
          pause,
          resume,
          restart,
        } = useTimer({ expiryTimestamp, onExpire: () => void finishTest() });
      
        return ( 
          <div style={{textAlign: 'center'}}>
            
            <div id="timer">
              <span className="time-left">{hours}</span><span className="timer-space">:</span><span className="time-left">{minutes}</span><span className="timer-space">:</span><span className="time-left">{seconds}</span>
            </div>
          </div>
        );
      }

    const catArr = [id];
    const time = new Date();
    time.setSeconds(time.getSeconds() + quesData.length*60);

  var finishTest = function(){
    setTimeExpired(1);
  }

  const getQuestions = async () =>  {
        try {
           const json = JSON.stringify({ 
            catArray: catArr,     
        });
           const headers = { 
               'Content-Type': 'application/json'
           };
    
            const res = await axios.post('https://epolylearning.com/Services/polyquiz.svc/GetCatwiseTestQues',json,{headers}).then(response => {
                const testQues = response.data.GetCatwiseTestQuesResult;
                setQuesData(testQues);
           });
       } catch (error) {
        if (error.response){
            console.log(error.response);
            }else if(error.request){
                console.log(error.request);
            }else if(error.message){
                console.log(error.message);
            } 
       } 
   }


   const getSubcatQuestions = async () =>  {
    try {
       const json = JSON.stringify({ 
        catArray: catArr, 
    });

       const headers = { 
           'Content-Type': 'application/json'
       };
       const res = await axios.post('https://epolylearning.com/Services/polyquiz.svc/GetSubCatwiseTestQues',json,{headers}).then(response => {
            const testQues = response.data.GetSubCatwiseTestQuesResult;
           setQuesData(testQues);
       });
   } catch (error) {
    if (error.response){
        console.log(error.response);
        }else if(error.request){
            console.log(error.request);
        }else if(error.message){
            console.log(error.message);
        } 
   } 
}

const getRankData = () => {
  
  try {
     const json = JSON.stringify({ testCat: location.state, testName: id, });
     const headers = { 
         'Content-Type': 'application/json'
     };
     const res = axios.post('https://epolylearning.com/Services/polyquiz.svc/GetRankData', json, {headers}).then(response => {
         const RankData = response.data;
         for (var i = 0; i< RankData.GetRankDataResult.length; i++){
            filteredScoreArray.push(RankData.GetRankDataResult[i].Score);
        }
        setRankArray(filteredScoreArray);
     });
 } catch (error) {
     console.log(error);
 } 
}

useEffect(() => {
    if(type == 'category-test'){
      getQuestions();
    }
    else if(type == 'subcategory-test'){
      getSubcatQuestions();
    }
    getRankData() 
   }, []);

    return (
        <>

<MetaTags>
            <title>{id} Online Quiz - PolyQuiz</title>
            <meta id="meta-description" name="description" 
             />       
</MetaTags>

 <Navbar />
<Row className='test-head-div'>
  {/* <Row justify='center'> */}
<Col className="head-div" xs={24} sm={24} lg={8}>

        <div className="heading-container">
        <Title  className = "test-heading head-title" level={4}> {id} Online Test</Title>
        </div>
        </Col>

       
        <Col xs={24} sm={24} lg={12}>
        <div className="mark-time-container">
      {(state == 'console') ? <Title level={5} className = "test-heading">Max Marks : {quesData.length}</Title>: ""}
     
    {(state == 'console') ? <Title level={5} className = "test-heading">Time Left<MyTimer expiryTimestamp={time} /></Title>: ""}
    </div>
    </Col>
    </Row>
    {/* </Row> */}
       
        {(state == 'gen') ? <GeneralInstructions quesLength ={quesData.length} /> : ""}
           <div id="next-btn">
           {
           (state == 'gen') ?
           <>
           <Button onClick={() => setState('test-ins') } type="primary" className="login-form-button next-btn">Next</Button> 
            </>
           : ""}
            </div>
            
            {(state == 'test-ins') ? <TestInstructions catName ={id} quesLength ={quesData.length} /> : ""}

            {(state == 'console') ? <TestConsole quesData = {quesData} catName ={id} type = {type} testId = {testId} MyTimer = {MyTimer} time = {time} timeExpired={timeExpired} rankArray = {rankArray}/> : ""}
        
            <div id="next-btn">
           {(state == 'test-ins') ? <Button onClick={() => setState('console') } type="primary" className="login-form-button next-btn">I'm Ready to Begin</Button> : ""}
            </div>
        </>
    )
}

export default OnlineTestPage
