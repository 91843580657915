import React from 'react';
import { useState, useEffect } from "react";
import axios from 'axios';
import { MetaTags } from 'react-meta-tags';
import { useNavigate } from "react-router-dom";
import '../styles/loginpage.css';
import Logo from '../images/logo_transparent.png';
import {Link} from 'react-router-dom';
import Navbar from '../components/Navbar';
import { Form, Input, Button ,Typography } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import BottomFooter from '../components/BottomFooter';

const ForgetPasswordPage = () => {

    const [nomailError, setNoEmailError] = useState(false);
    const [notActiveError, setNotActiveError] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);

    var item ="";
    const navigate = useNavigate();

    const itemStr = localStorage.getItem('isLogin');
    item = JSON.parse(itemStr)
    const now = new Date()

    const {Title}  =Typography;

  
      function onFinish(data) {
        setNoEmailError(false);
        setSuccessMsg(false);

          try {
             const json = JSON.stringify({ email: data.email});
             const headers = { 
                 'Content-Type': 'application/json'
             };
            
             const res = axios.post('https://epolylearning.com/Services/polyquiz.svc/ForgotPassword', json, {headers}).then(response => {
                 const userData = response.data;
                
                 if(userData.ForgotPasswordResult.FirstName == "No Such Email."){
                   setNoEmailError(true);
                 }
                 else if(userData.ForgotPasswordResult.Email != undefined && userData.ForgotPasswordResult.IsActivated == true){
                   setSuccessMsg(true);
                  }
                  else if(userData.ForgotPasswordResult.Email != undefined && userData.ForgotPasswordResult.IsActivated == false){
                  setNotActiveError(true);
                  }
             });
         } catch (error) {
             console.log(error);
         } 
      }

      useEffect( () => {

        if (itemStr && item.expiry > now.getTime()) {
          navigate('/');
        }
  
      }, [])

    return (
        <>
          <MetaTags>
            <title>Reset Your Password - PolyQuiz</title>
            <meta id="meta-description" name="description" 
             />
          </MetaTags>

            <Navbar />
            <div className='BoxContainer'>
              <div className='FormContainer'>
    
              <div className='signup-heading'>
  <img width="150px" height="100px" src={Logo} alt="Test and Rank logo" />
  </div>
  <div className='login-heading'>
    <Title className='signup-heading' level={3}>Forgot Password</Title>
  </div>
    
        <div className='sendmail'>
          <p>Enter the Email id you have chosen at the time of Sign Up.</p>
        </div>
    
            <div className="Login">

            <Form
      name="normal_login"
      
      className="login-form"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
      <Form.Item
        name="email"
        rules={[
          {
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
          {
            required: true,
            message: 'Please input your E-mail!',
          },
        ]}
      >
        <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
      </Form.Item>
     
     

      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
        Send Reset Link
        </Button>
      </Form.Item>
    </Form>
              </div>
    
              {notActiveError ?<div className="invalid-user">Account associated with this Email is not verified. Please check your mail for the verification Link.</div> : ''}
    
              {nomailError ?<div className="invalid-user">There is no account associated with this Email.
              Please <Link to="/register">Sign Up</Link> to get started.
              </div> : ''}
    
              {successMsg ?<div className="invalid-user">Mail sent successfully. Please check your mail for password reset link. </div> : ''}

              <div className='signup-heading'>
          <span className='login-text login-heading'>Don't have an account?  <Link to="/register">Sign Up</Link></span>
          </div>
           
              </div>
            </div>

            <BottomFooter />
            </>
      );
};

export default ForgetPasswordPage;
