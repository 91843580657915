import {React, useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { Menu, Typography, message, Row, Col } from 'antd';
import { RightCircleFilled } from '@ant-design/icons';
import '../styles/homepagesider.css'

const HomePageSider = ({ allCategories, allSubCategories }) => {

  const { SubMenu } = Menu;

  const [openKeys, setOpenKeys] = useState(['sub1']);
  const [current, setCurrent] = useState(1);

  const navigate = useNavigate();

  
  const { Title } = Typography;
  // var item ="";
  const itemStr = localStorage.getItem('isLogin');
  const item = JSON.parse(itemStr)
  const now = new Date()

  if(!itemStr || item.expiry < now.getTime()){
      localStorage.clear();
      
  }

  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const handleClick = (e) =>{
        
        setCurrent(e.key);
    }

    const success = () => {
      message.warning('You need to Log in or Sign up to get Started!', 3);
    };  

    const catArray = Array.from(allCategories);

    const subCatArray = Array.from(allSubCategories);

    const rootSubmenuKeys = [];
    

    for (var i = 0; i < catArray.length; i++) {
        rootSubmenuKeys.push(catArray[i].CategoryName)
    } 
    
  return (
    
<>
<Row>
  <Col >
<Title id="sidebar-heading" level={4}>Browse Quizzes</Title>
<Menu onClick={handleClick} mode="inline" theme='dark' openKeys={openKeys} onOpenChange={onOpenChange} style={{ width: 300 }}>
      
{catArray.map((curCat, key) => {
             
             return(
      
      <SubMenu key={curCat.CategoryName} icon={<RightCircleFilled /> } title={curCat.CategoryName}>
          {subCatArray.map((curSubCat, key) => {
              if(curCat.CategoryId == curSubCat.CategoryId){
                  return(
                    (itemStr) ?
                      <Menu.Item key={curSubCat.SubCatId}><Link to={{
                        pathname: `/free-online-quiz/${curSubCat.SubCatName.replaceAll(" ","-")}`,}}
                        state={{ type: "subcategory-test" }}
                        
                    >{curSubCat.SubCatName}</Link></Menu.Item>:

                    <Menu.Item key={curSubCat.SubCatId}><span 
                    onClick={success}>{curSubCat.SubCatName}</span></Menu.Item>
                  )
              } 
               
                  })
          }

      
      </SubMenu>
             )})}
     
    </Menu>
    </Col></Row>
      </>

  )
    }

export default HomePageSider