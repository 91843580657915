import React from 'react';
import { MetaTags } from 'react-meta-tags';
import Navbar from '../components/Navbar';
import BottomFooter from '../components/BottomFooter';
import {Row, Col} from 'antd';
import '../styles/faqspage.css';
import { Typography, Divider } from 'antd';


const AboutUsPage = () => {
    const { Title, Paragraph, Text, Link } = Typography;
  return (
  <>
<MetaTags>
            <title>About Us - Poly Quizz</title>
            <meta id="meta-description" name="description" />     
</MetaTags>

<Navbar />
<Row className='about-us-div'>

    
    <Col className='page-content-col'>
    <Typography>
           

           
            <Title level={3}>A breif Introduction to Poly Quizz</Title>
            <Divider />

<Paragraph>The aim of Poly Quizz is to provide educational quizzes and resources for students, teachers and parents that help make education fun and engaging for students, 
    taking important concepts and putting them
    into a form that they can not only understand but also enjoy.</Paragraph>

<Paragraph>Inspiring an interest in knowledge at a young age can help students grow up with the tools needed to form questions about the world around them
     and make decisions based on their own reasoning.</Paragraph>

<Paragraph>We started this site with only few type of online quizzes but the goal is to cover 
all topics related to school and college level education. The ultimate goal is to create a 
community online learners for sharing knowledge and developing skills.</Paragraph>

<Paragraph>As a small team is maintaining this site, it may have some issues/bugs. Please feel free to 
point out them and let us know. Any feedback regarding content, design and user Experience will 
be greatly appreciated. You can contact us through E-mail or Facebook.</Paragraph>

<Paragraph>Let’s together make web a learning place.</Paragraph>
<Title level={3}>Technologies powering this site</Title>

<Paragraph>This site is built using React Web Development framework.</Paragraph>

</Typography>

</Col>

</Row>

<BottomFooter />
  </>
  )
};

export default AboutUsPage;
