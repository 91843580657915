import React from "react";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css';
import Home from './pages/HomePage';
import SubCategory from "./pages/SubCategoryPage";
import OnlineTest from "./pages/OnlineTestPage";
import TestResult from "./pages/TestResultPage";
import TestSolution from "./pages/TestSolutionPage";
import Signup from "./pages/SignupPage";
import Login from "./pages/LoginPage";
import AboutUs from "./pages/AboutUsPage";
import ActivationMail from "./pages/ActivationMailPage";
import ChangePassword from "./pages/ChangePasswordPage";
import FAQs from "./pages/FaqsPage";
import ForgetPassword from "./pages/ForgetPasswordPage";
import PrivacyPolicy from "./pages/PrivacyPolicyPage";
import ResetPassword from "./pages/ResetPasswordPage";
import UserActivation from "./pages/UserActivationPage";
import UserProgress from "./pages/UserProgressPage";
import GoToTop from "./components/GoToTop";


function App() {

  return (
 
    <Router>     
   
    <GoToTop />
      
      <Routes>
        
        <Route path = "/" exact element={ <Home /> } />
        <Route path = "/online-quiz-test/">
          <Route path=":catName" element = {<SubCategory />} />
        </Route>
        <Route path = "/free-online-quiz/"> 
           <Route path=":id" element = {<OnlineTest />} />   
        </Route>
        <Route path = "/online-quiz-test-result/"> 
           <Route path=":id" element = {<TestResult />} />   
        </Route>
        <Route path = "/online-quiz-test-answers/"> 
           <Route path=":id" element = {<TestSolution />} />   
        </Route>   
        <Route path = "/auth/password-reset/"> 
           <Route path=":code" element = {<ResetPassword />} />   
        </Route>  
        <Route path = "/auth/user-activation/"> 
           <Route path=":code" element = {<UserActivation />} />   
        </Route> 
        <Route path = "/register" exact element={ <Signup /> } />
        <Route path = "/login" exact element={ <Login /> } />
        <Route path = "/about" exact element={ <AboutUs /> } />
        <Route path = "/auth/resend-activation-mail" exact element = {<ActivationMail /> } />
        <Route path = "/auth/change-user-password" exact element = {<ChangePassword /> } />
        <Route path = "/frequently-asked-questions" exact element = {<FAQs /> } />
        <Route path = "/auth/reset-forgot-password" exact element = {<ForgetPassword /> } />
        <Route path = "/privacy-policy" exact element = {<PrivacyPolicy /> } />
        <Route path = "/check-user-progress/" exact element = {<UserProgress /> } />


      </Routes>
    
    </Router>
    
  
    
  );
}

export default App;
