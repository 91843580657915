import { Col, Row } from 'antd';
import { __SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED } from 'react';
import {React} from 'react';

const MiddleTestConsole = ({questions, curQues, nextQues, preQues, clearResponse, setAnswer, ansArray}) => {

    return (
      <>
      <div id="console-div">
        <div id="ques-div">
            <table className="table">
  <thead>
    <tr>
      <th id ="option-column" scope="col">Que. {curQues+1}</th>
      <th id="ques-heading" scope="col"><span class="questionpre">{questions[curQues].Question}</span></th>
    </tr>

{questions[curQues].Image1 &&
    <tr>
    <td></td>
      <td scope="col" class="img-column">
      <img 
      src={`https://epolylearning.com/QuestionImages/${questions[curQues].QuestionId}-1.png`}
      alt="new"
      />
        </td> 
    </tr>
}

{questions[curQues].Image2 &&
    <tr>
    <td></td>
      <td scope="col">
      <img 
      src={`https://epolylearning.com/QuestionImages/${questions[curQues].QuestionId}-2.png`}
      alt="new"
      />
        </td>  
    </tr>
}


  </thead>
  <tbody>
    <tr className='row-stripped'>
      <th scope="row"><input type="radio" className="ans-radio" id = "optiona" onClick={(e) => {setAnswer(e.target.value)}} value = {questions[curQues].Option1} name="selected_ans" /></th>
      <td className='option-row'>{questions[curQues].Option1}</td>
    </tr>
  

    <tr>
      <th scope="row"><input type="radio" className="ans-radio" id = "optionb" onClick={(e) => {setAnswer(e.target.value)}} value = {questions[curQues].Option2} name="selected_ans" /></th>
      <td className='option-row'>{questions[curQues].Option2}</td>
    </tr>
    

    <tr className='row-stripped'>
      <th scope="row"><input type="radio" className="ans-radio" id = "optionc" onClick={(e) => {setAnswer(e.target.value)}} value = {questions[curQues].Option3} name="selected_ans" /></th>
      <td className='option-row'>{questions[curQues].Option3}</td>
    </tr>
   

    <tr>
      <th scope="row"><input type="radio" className="ans-radio" id = "optiond" onClick={(e) => {setAnswer(e.target.value)}} value = {questions[curQues].Option4} name="selected_ans" /></th>
      <td className='option-row'>{questions[curQues].Option4}</td>
    </tr>
    

  </tbody>
</table>
        </div>


<Row>       
    
<div id="next-prev">

  <Col lg={8} xs={8}>
  <div id ="prev" className="col"><button onClick = {preQues} type="button" className="btn btn-info ques-nav-btn">Prev</button></div>
  </Col>
  <Col lg={8} xs={8} >
   
  <div id="next" className="col"><button onClick = {nextQues} type="button" className="btn btn-info ques-nav-btn">Next</button></div>
  </Col>
  
  <Col lg={8} xs={8} >
  
  
  <div id="clear-res" className="col"><button onClick = {clearResponse} type="button" className="btn-dark">Clear Response</button></div>
  
  </Col>
  
</div>

</Row> 


</div>
        
        </>
    )
}

export default MiddleTestConsole
