import React from 'react'
import '../styles/homepagecard.css'
import images from './ImagesImport';
import { Link } from "react-router-dom";
import { Card, Space, Layout, Button, message } from 'antd';

const HomePageCard = ({ allCategories}) => {    

    const { Meta } = Card;
    
    
    const catArray = Array.from(allCategories);
    // const catDataArray = Array.from(catData);
    const itemStr = localStorage.getItem('isLogin');
    const item = JSON.parse(itemStr)
    const now = new Date()

    if(!itemStr || item.expiry < now.getTime()){
        localStorage.clear();
        
    }

    const success = () => {
      message.warning('You need to Log in or Sign up to get Started!', 3);
    }; 

    return (
        <>
           <Layout id='card-layout'> 
           <Space size={[16, 64]} wrap>
          {catArray.map((curCat, key) => {
             
              return(
                  <>            
                   <Card
    style={{ width: 300 }}
    cover={
      <img
        alt="example"
        height="230px"
        src= {images[key]}
      />
    }
    actions={[
      
      
      <Button className='quiz-action-btn'>
      {(itemStr) ? 
      <Link to={{
        pathname: `/free-online-quiz/${curCat.CategoryName.replaceAll(" ","-")}`,   
    }} state={{ type: "category-test" }} type="primary"><span className='quiz-btn'>Start Quiz Now</span></Link>
    : <span onClick={success} className='quiz-btn'>Start Quiz Now</span>}
    
    </Button>,
      
      
      <Link to={{
        pathname: `/online-quiz-test/${curCat.CategoryName.replaceAll(" ","-")}`,
        
    }} type="link"><span className='subcat-quiz-link'>View all Quizzes under {curCat.CategoryName}</span></Link>
      //<SettingOutlined key="setting" />,
      // <EditOutlined key="edit" />,
      // <EllipsisOutlined key="ellipsis" />,
    ]}

  >

    <Meta
      title={curCat.CategoryName}
    //  description="This is the description"
    />
  </Card>
                  </>
              )

          })}
</Space>
</Layout>         
        </>    
    )
}

export default HomePageCard