import {React, useState, useEffect} from 'react';
import { useParams } from "react-router-dom";
import { Row, Col, PageHeader, Typography} from 'antd';
import MetaTags from 'react-meta-tags';
import Navbar from '../components/Navbar';
import axios from 'axios';
import SubCatCard from '../components/SubcatCard';
import BottomFooter from '../components/BottomFooter';
import '../styles/subcategorypage.css';

const SubCategory = () => {
    
    const [allSubCategories, setAllSubCategories] = useState({});

    let params = useParams();

    const id = params.catName.replaceAll("-"," ");

    const { Title } = Typography;
    
    const getAllSubCategories = async () =>  {
         try {
            const json = JSON.stringify({ id: id });
            const headers = { 
                'Content-Type': 'application/json'
            };
           
            const res = await axios.post('https://epolylearning.com/Services/polyquiz.svc/Category', json, {headers}).then(response => {
                const subCatData = response.data.CategoryResult.SubCategoriesTypes;
                setAllSubCategories(subCatData);
            });
        } catch (error) {
            console.log(error);
        } 

    }
    
    useEffect(() => {
       getAllSubCategories();
    }, [id]);

    return (
        
        <>
        <MetaTags>
            <title>{id} Chapterwise online quizzes - PolyQuiz</title>
          </MetaTags>
        
        <Navbar />
        <Row className='block-row'>
        <Row className='subcat-heading-div'>
        
<Col xl={24} xs={24} sm={24}>
<PageHeader
    className="site-page-header"
    title={<Title level={2}>{id}</Title>}
    subTitle={<p className='subcat-desc'>Here you can attempt Chapterwise MCQ online tests under the topic {id}. MCQ online test of individual topic will 
    cover questions from specific topic only. Check below the various quizzes avlaible under {id}. </p>}
  />
    </Col>
    </Row>
   


    <Row className='sub-card-container'>
        <Col span={24}>
       <SubCatCard allSubCategories = {allSubCategories} />
       
       </Col>
    </Row>
    </Row>
       <BottomFooter />
       </>
    )
}

export default SubCategory

