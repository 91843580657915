import Aptitude from "../images/Aptitude.jpeg";
import LogicalReasoning from "../images/Logical Reasoning.jpeg";
import GeneralKnowledge from "../images/General Knowledge.jpeg";
import Microbiology from "../images/Microbiology.jpeg";
import BasicChemicalEngineering from "../images/Basic Chemical Engineering.jpeg";
import HeatTransfer from "../images/Heat Transfer.jpeg";
import Biology from "../images/Biology.jpeg"
import Chemistry from "../images/Chemistry.jpeg"
import Biotechnology from "../images/Biotechnology.jpeg"
import MechanicalEngineering from "../images/Mechanical Engineering.jpeg"
import Biochemistry from "../images/Biochemistry.jpeg"
import BiochemicalEngineering from "../images/Biochemical Engineering.jpeg"
import VerbalAbility from "../images/Verbal Ability.jpeg"
import VerbalReasoning from "../images/Verbal Reasoning.jpeg"
import NonVerbalReasoning from "../images/Non Verbal Reasoning.jpeg"
import DataInterpretation from "../images/Data Interpretation.jpeg"
import CivilEngineering from "../images/Civil Engineering.jpeg"
import ElectronicsandCommunicationEngineering from "../images/Electronics and Communication Engineering.jpeg"
import MarketingandMarketingManagement from "../images/Marketing and Marketing Management.jpeg"
import HumanResourceManagement from "../images/Human Resource Management.jpeg"
import FinancialManagementandFinancialMarkets from "../images/Financial Management and Financial Markets.jpeg"
import CostAccounting from "../images/Cost Accounting.jpeg"
import BusinessStatistics from "../images/Business Statistics.jpeg"
import FoodEngineering from "../images/Food Engineering.jpeg"
import DairyEngineering from "../images/Dairy Engineering.jpeg"
import IrrigationEngineering from "../images/Irrigation Engineering.jpeg"
import MechanicsofMaterials from "../images/Mechanics of Materials.jpeg"
import EnvironmentalEngineering from "../images/Environmental Engineering.jpeg"
import EnvironmentManagement from "../images/Environment Management.jpeg"
import EngineeringGeology from "../images/Engineering Geology.jpeg"
import GeneticEngineering from "../images/Genetic Engineering.jpeg"
import ProfessionalCommunication from "../images/Professional Communication.jpeg"
import HumanandCulturalDiversity from "../images/Human and Cultural Diversity.jpeg"
import InternationalRelations from "../images/International Relations.jpeg"
import CellBiology from "../images/Cell Biology.jpeg"
import Bioinformatics from "../images/Bioinformatics.jpeg"
import BasicElectricalEngineering from "../images/Basic Electrical Engineering.jpeg"
import GeotechnicalEngineering from "../images/Geotechnical Engineering.jpeg"
import FoundationEngineering from "../images/Foundation Engineering.jpeg"
import TextileEngineering from "../images/Textile Engineering.jpeg"
import Sociology from "../images/Sociology.jpeg"
import UIDAIAadhaarSupervisorCertification from "../images/UIDAI Aadhaar Supervisor Certification.jpeg"
import Surveying from "../images/Surveying.jpg"
import MassTransfer from "../images/Mass Transfer.jpg"
import Virology from "../images/Virology.jpg"
import EnvironmentalBiotechnology from "../images/Environmental Biotechnology.jpg"
import EnzymeTechnology from "../images/Enzyme Technology.jpg"
import TissueEngineering from "../images/Tissue Engineering.jpg"
import DrugandPharmaceuticalBiotechnology from "../images/Drug and Pharmaceutical Biotechnology.jpg"
import AnalyticalInstrumentation from "../images/Analytical Instrumentation.jpg"
import InstrumentationTransducers from "../images/Instrumentation Transducers.jpg"
import ElectricalMeasurementandInstrumentation from "../images/Electrical Measurement and Instrumentation.jpg"


export default [
    Aptitude,
    LogicalReasoning,
    GeneralKnowledge,
    Microbiology,
    BasicChemicalEngineering,
    HeatTransfer,
    Biology,
    Chemistry,
    Biotechnology,
    MechanicalEngineering,
    Biochemistry,
    BiochemicalEngineering,
    VerbalAbility,
    VerbalReasoning,
    NonVerbalReasoning,
    DataInterpretation,
    CivilEngineering,
    ElectronicsandCommunicationEngineering,
    MarketingandMarketingManagement,
    HumanResourceManagement,
    FinancialManagementandFinancialMarkets,
    CostAccounting,
    BusinessStatistics,
    FoodEngineering,
    DairyEngineering,
    IrrigationEngineering,
    MechanicsofMaterials,
    EnvironmentalEngineering,
    EnvironmentManagement,
    EngineeringGeology,
    GeneticEngineering,
    ProfessionalCommunication,
    HumanandCulturalDiversity,
    InternationalRelations,
    CellBiology,
    Bioinformatics,
    BasicElectricalEngineering,
    GeotechnicalEngineering,
    FoundationEngineering,
    TextileEngineering,
    Sociology,
    UIDAIAadhaarSupervisorCertification,
    Surveying,
    MassTransfer,
    Virology,
    EnvironmentalBiotechnology,
    EnzymeTechnology,
    TissueEngineering,
    DrugandPharmaceuticalBiotechnology,
    AnalyticalInstrumentation,
    InstrumentationTransducers,
    ElectricalMeasurementandInstrumentation
    
];