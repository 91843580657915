import {React, useEffect, useState} from 'react';
import { Link, Navigate } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import '../styles/testresultpage.css';
import Navbar from '../components/Navbar';
import { Statistic, Card, Row, Col, Typography, Button, Tooltip } from 'antd';

const TestResultPage = (props) => {

  const [score, setScore]  = useState(0);
  const [subcats, setSubcats]  = useState([]);
  const [attemptedques, setAttemptedQues]  = useState([]);
  const [correctques, setCorrectQues]  = useState([]);
  const[userPercentile, setUserPercentile] = useState(0);
  const [userRank, setUserRank] = useState(0);

  const { Text, Title } = Typography;

  const navigate = useNavigate();
  const location = useLocation();
  var item ="";

  const itemStr = localStorage.getItem('isLogin');
     item = JSON.parse(itemStr)
     const now = new Date()


     
    
    const filteredScoreArray = [];
    let count = 0;
    let userId = "";
    let rank = 0;
    let result = [];
    let testName = "";
    let percentile = 0;

    let quesList = [];
    let correctquesList = [];

    let catName = " ";
    let quesData = " ";
    let ansArray = " ";
    let testType = " ";
    let testId = " ";
    let rankArray = " ";

    let scoreData = "";
    let attemptData = "";

    if(location.state){
         catName = location.state.catName;
         quesData = location.state.quesData;
         ansArray = location.state.ansArray;
         testType = location.state.type;
         testId = location.state.testId;
         rankArray = location.state.rankArray;

         scoreData = `${score}/${quesData.length}`;
 attemptData = `${ansArray.filter(ans => ans != undefined).length}/${quesData.length}`;
      }
   
    if(itemStr){
      userId = item.userid;
    }
    
    if(testType == 'subcategory-test'){
      testName = quesData[0].SubCatName;        
    }

    if(testType == 'category-test'){
      testName = quesData[0].CategoryName;        
    }

    const submitTest = () =>{
     ansArray.map((ans,key) =>
      {if(ansArray[key] == quesData[key].Answer){
          count = count+1;
      }}
      )
      setScore(count);
      filterScore();  
  }

  const getSubCategories = () =>{
        const items = quesData.reduce((catsSoFar, {SubCatId, SubCatName}) => {
        if (!catsSoFar[SubCatId]) catsSoFar[SubCatId] = [];
        catsSoFar[SubCatId].push(SubCatName);
        return catsSoFar;
      }, {});
      
     result = Object.values(items)
     setSubcats(result);
    }

   const attemtedQues = () => {
    let i =0;
    const items = ansArray.reduce((catsSoFar) => { 
        if (ansArray[i] != undefined){
          if(!catsSoFar[quesData[i].SubCatId])
          {
            catsSoFar[quesData[i].SubCatId] = [];
          }
          catsSoFar[quesData[i].SubCatId].push(quesData[i].SubCatName);
        }
        
      if(i<ansArray.length){i++}
      return catsSoFar;
  }, {});
  quesList = Object.values(items);
    
  setAttemptedQues(quesList);
  }

  const filterScore = () => {
    for (var i = 0; i< rankArray.length; i++){
      if(rankArray[i] < count ){
        filteredScoreArray.push(rankArray[i]);
      }
    }
    calculatePercentile();
    calculateRank();
  }

  const calculatePercentile = () => {
    if(filteredScoreArray.length == 0 && rankArray.length == 0){
      percentile = 100;
       setUserPercentile(percentile);
    }
    else{
      percentile = (Math.round((filteredScoreArray.length/rankArray.length)*100));
      setUserPercentile(percentile);
    } 
  }

  const calculateRank = () => {
      rank = (Math.round((100 - percentile) * (rankArray.length/100) +1));
      setUserRank(rank);  
  }

  const correctQues = () => {
    
   let i =0;
   const items = ansArray.reduce((quesSoFar) => {
         if (ansArray[i] != undefined && ansArray[i] == quesData[i].Answer){
          if(!quesSoFar[quesData[i].SubCatId])
         {
          quesSoFar[quesData[i].SubCatId] = [];
         }
         quesSoFar[quesData[i].SubCatId].push(quesData[i].SubCatName);
       }
       
     if(i<ansArray.length){i++}
     return quesSoFar;

 }, {});

 correctquesList = Object.values(items);
 setCorrectQues(correctquesList);  
 }
  
 const submitTestData = () => {
  try {
     const json = JSON.stringify({testid : testId, userid: userId, maxscore: quesData.length, score: count, testcat: testType, testname: testName, rank: rank, percentile: percentile, attempted: ansArray.filter(ans => ans != undefined).length });
     const headers = { 
         'Content-Type': 'application/json'
     };
    const res = axios.post('https://epolylearning.com/Services/polyquiz.svc/SubmitUserScore', json, {headers}).then(response => {    
     });
 } catch (error) {
     console.log(error);
 } 
}

// const scoreData = `${score}/${quesData.length}`;
// const attemptData = `${ansArray.filter(ans => ans != undefined).length}/${quesData.length}`;


useEffect(() => {

  if (!itemStr || item.expiry < now.getTime()) {
    navigate('/login');
    window.location.reload(false);
   
  }   
   
  
    submitTest()
    getSubCategories()
    attemtedQues()
    correctQues()
    submitTestData()      
  },[])

    return (
        <>

<MetaTags>
            <title>{catName} Online Quiz Test Result  - PolyQuizz</title>
            <meta id="meta-description" name="description" 
             />   
</MetaTags>

<Navbar />

<Row className='test-head-div'>
{/* <div className="head-div">
<div className='heading-timer'> */}
<Col>
        {/* <div className="heading-container"> */}
        <Title  className = "test-heading" level={4}> {catName} Online Test</Title>
        {/* </div> */}
        </Col>
        {/* </div>
        </div> */}
        </Row>

        {/* <Row className="result-container"> */}
          
  <Row className="result-block">
    <Col xs={24} lg={24}>
    <div className="eight">
      <h1>Overall Performance Summary</h1>
    </div>
    </Col>
  </Row>

{/* <div className="site-statistic-demo-card"> */}
    <Row className='result-summary'>
      <Col lg={4} xs={24}>
        <Card className='score-card'>
          <Statistic
            title="Score"
            value= {scoreData}
            precision=""
            valueStyle={{ color: '#3f8600' }}
            // prefix={<ArrowUpOutlined />}
            suffix=""
          />
         
        </Card>
      </Col>
      <Col lg={4} xs={24}>
        <Card className='score-card'>
          <Statistic
            title="Rank"
            value={userRank}
            precision=""
            valueStyle={{ color: '#3f8600' }}
            // prefix={<ArrowDownOutlined />}
            suffix=""
          />
        </Card>
      </Col>


      <Col lg={4} xs={24}>
        <Card className='score-card'>
          <Statistic
            title="Attempted"
            value={attemptData}
            precision=""
            valueStyle={{ color: '#3f8600' }}
            // prefix={<ArrowDownOutlined />}
            suffix=""
          />
        </Card>
      </Col>

      <Col lg={4} xs={24}>
        <Card className='score-card'>
          <Statistic
            title="Accuracy"
            value={ correctques.length>0 ? ((score)/(ansArray.filter(ans => ans != undefined).length)*100).toFixed(2) : 0}
            precision=""
            valueStyle={{ color: '#3f8600' }}
            // prefix={<ArrowDownOutlined />}
            suffix="%"
          />
        </Card>
      </Col>

      <Col lg={4} xs={24}>
        <Card className='score-card'>
          <Statistic
            title="Percentile"
            value={userPercentile}
            precision=""
            valueStyle={{ color: '#3f8600' }}
            // prefix={<ArrowDownOutlined />}
            suffix=""
          />
        </Card>
      </Col>


    </Row>
  {/* </div> */}



  <Row className="result-block">
    <Col>
    <div className="eight">
      <h1>Sectional Performance Summary</h1>
    </div>
    </Col>
  </Row>



  <Row className="result-block">
    <Col span={24}>
      <div className="performance-card">
      <table className="table result-table">
  <thead>
    <tr className="table-dark">
      <th scope="col">Chapter Name</th>
      <th scope="col">Total Questions</th>
      <th scope="col">Attempted</th>
      <th scope="col">Score</th>
    </tr>
  </thead>
  <tbody className='summary-table-body'>
  
 {
   subcats.map((item, key) => {
     
     return(
     <>
     <tr>
      <th className="table-success" scope="row">{item[0]}</th>
      <td className="table-primary total-ques">
      
     { testType == 'category-test' ?
    <div class="progress-bar total-progress" role="progressbar" style={{ width: `${item.length*5}%` }} aria-valuenow={item.length} aria-valuemin="0" aria-valuemax="30">
      <span class="summary-report">{item.length}</span>
    </div>
    :
<div class="progress-bar total-progress" role="progressbar" style={{ width: `${item.length*3.35}%` }} aria-valuenow={item.length} aria-valuemin="0" aria-valuemax="30">
<span className="summary-report">{item.length}</span>
    </div>
  }
      </td>
    
      <td className="table-active attempt-ques">
       {attemptedques.map((elem,key) => {
         
        if(item.indexOf(elem[0]) != -1 ){
       return(
          testType == 'category-test'?
          <div class="progress-bar attempt-progress" role="progressbar" style={{ width: `${elem.length*10}%` }} aria-valuenow={elem.length} aria-valuemin="0" aria-valuemax={ansArray.length}>
      <span class="summary-report">{elem.length}</span>
    </div>
    :
    <div class="progress-bar attempt-progress" role="progressbar" style={{ width: `${elem.length*3.35}%` }} aria-valuenow={elem.length} aria-valuemin="0" aria-valuemax={ansArray.length}>
      <span className="summary-report">{elem.length}</span>
    </div>
      
       )
       } 
           } 
     )}
</td>

<td className="table-info score-ques">
       {correctques.map((elem,key) => {
        if(item.indexOf(elem[0]) != -1 ){
       return(
          testType == 'category-test'?
          <div class="progress-bar score-progress" role="progressbar" style={{ width: `${elem.length*10}%` }} aria-valuenow={elem.length} aria-valuemin="0" aria-valuemax="4">
          <span class="summary-report">{elem.length}</span>
        </div>
        :
        <div class="progress-bar score-progress" role="progressbar" style={{ width: `${elem.length*3.35}%` }} aria-valuenow={elem.length} aria-valuemin="0" aria-valuemax="4">
        <span className="summary-report">{elem.length}</span>
      </div>
       )
       } 
           } 
     )}
</td>
  </tr>          
</>

     )
    })
    }
    
    {testType == 'category-test'?
    <tr className="table-dark">
      <th scope="col">Total</th>
      <th scope="col">{quesData.length}</th>
      <th scope="col">{ansArray.filter(ans => ans != undefined).length}</th>
      <th scope="col">{score}</th>
    </tr>   
    :
    ""
}

  </tbody>
</table>

        </div>
        </Col>
        </Row>

       

    <Row className="result-block">
    <Col>
    <div className="eight">
      <h1>Question Wise Analysis</h1>
    </div>
    </Col>
  </Row>


<Row><Col xs={24}>
    <div className="legend-holder-div">
    <div className="legend-holder">
   
    <Button type='primary' shape='circle'  className="ques-tooltip-correct legend-demo"></Button>
    <span>Attempted and Correct</span>
    
    <Button type="primary" shape='circle' className="ques-tooltip-wrong legend-demo"></Button>
    <span>Attempted and Wrong</span>
    
    <Button type="primary" shape='circle' className="ques-tooltip legend-demo"></Button>
    <span>Unattempted</span>
  
      
      </div>
  </div>
  </Col>
  </Row>

  <div className="row">
    <div className="col-sm">
      <div className="performance-card">

      <table className="table result-table">
  <thead>
    <tr className="table-dark">
      <th scope="col">Chapter Name</th>
      <th scope="col">Questions</th>
    </tr>
  </thead>
  <tbody className='question-analysis-table'>

 {
   subcats.map((item, key) => {
    
     return(
     <>
     <tr>
      <th className="table-success" scope="row">{item[0]}</th>
      <td className="table-primary question-review">
      
      {quesData.map((elem,key) => {
         
         if(item[0] == elem.SubCatName  ){

          if(ansArray[key] == elem.Answer){
            return(
              <div>
           <Tooltip color='#132730' title={elem.Question} key={elem.QuestionId}><button className="ques-tooltip-correct tooltip-div">
  {quesData.indexOf(elem)+1}
  
</button>
</Tooltip>

</div>
             )
          }
          else if(ansArray[key] == undefined){
        return(
          
           <div>
  <Tooltip color='#132730' title={elem.Question} key={elem.QuestionId}> <button className="btn ques-tooltip tooltip-div">
  {quesData.indexOf(elem)+1}
  
</button>
</Tooltip> 

</div>
         
        )
          }
          else if(ansArray[key] != elem.Answer){

            return(
              <div>
  <Tooltip color='#132730' title={elem.Question} key={elem.QuestionId}> <button className="btn ques-tooltip-wrong tooltip-div">
     {quesData.indexOf(elem)+1}
     
   </button>
   </Tooltip>
   </div>
     
           )

          }
        } 
 
            } 
      )}
      </td>
    
  </tr>  
       
</>

     )
    })
    }

  </tbody>
</table>
        </div>
        </div>
        </div>


        <div className='row result-block btn-container'>
        <div className="performance-card">
  
        <Link to={`/online-quiz-test-answers/${catName.replaceAll(" ","-")}`}
            
            state= {{ansArray: ansArray, catName: catName, quesData: quesData}}
        className="btn btn-danger sub-btn" >View Solution</Link>

<Link to={{
    pathname: '/',
}}
        className="btn btn-danger sub-btn">Go back to Tests</Link>
        </div>
        </div>

        
       
{/* </Row> */}

        </>
    )
}

export default TestResultPage

