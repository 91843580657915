import React from 'react';
import { Row, Col, Divider, Button } from 'antd';
import '../styles/topcontainer.css';
import Logo from '../images/logo_transparent.png';
import { Typography } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';
import {Link} from 'react-router-dom';

const TopContainer = () => {

  var item ="";
  const itemStr = localStorage.getItem('isLogin');
  item = JSON.parse(itemStr)
  const now = new Date()

    const { Title } = Typography;
  return (
    <Row>
      <Col xs={24} xl={24} span={24}>
      <div className="mainContainer">
            <div className="bgFilter">
            <Row>
      <Col xs={24} xl={8} span={8}>
      <div className="UpperLayer">
    <img width="300px" height="200px" src={Logo} alt="Test and Rank logo" />
    </div>
      </Col>
      <Col xs={24} xl={16} span={16}>
      <div className="UpperLayer">
          <Title className='main-title'>Ulimate destination to play quizzes and 
          online tests to build your knowledge
          on variety of topics.</Title>
          <Divider />
          <div className='DescText'>
          Welcome to the PolyQuizz. Here you can test your Knowledge with the help of hundreds of MCQ based online
           quizzes and tests. Attempt an instant online test to check your knowledge in topics like Aptitude, 
           Biology, Chemistry, Mechanical Engineering, Verbal Reasoning, Data Interpretation,
           Civil Engineering, Business Statistics, Environmental Engineering, and many more.
          </div>
          </div>
      </Col>
      <Col xs={24} xl={12} span={12}>
      <div>
   <h2><div class="Features">
     <div>
     <p><DoubleRightOutlined /> Attempt unlimited quizzes in the form of multiple choice questions.</p>
         <p><DoubleRightOutlined /> See your results instantly after completeing the Quiz.</p>
         <p> <DoubleRightOutlined /> Compare your performance with other top performers using Rank and Percentile metric.</p>
         <p> <DoubleRightOutlined /> Chapterwise Quizzes are also avaliable on all topics .</p>
        
    </div>
    </div></h2>
    </div>
      </Col>
      <Col id='signup-btn-col' xs={24} xl={12} span={12}>
      {(!itemStr) ?
      <Button size = 'large' id='top-container-btn' type="primary">
          <Link to='/register'>Get Started Now - It's Free</Link>
        </Button> : <></>}
      </Col>
    </Row>
                </div>
                </div>
      </Col>
    </Row>
  )
}

export default TopContainer