import {React, useEffect} from 'react';
import MetaTags from 'react-meta-tags';
import Navbar from '../components/Navbar';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import { Row, Col, Typography } from 'antd';
import '../styles/testsolutionpage.css';

const TestSolutionPage = (props) => {

  var item ="";
  const navigate = useNavigate();
  const location = useLocation();

  let catName = "";
  let quesData = "";
  let ansArray = "";

  const itemStr = localStorage.getItem('isLogin');
     item = JSON.parse(itemStr)
     const now = new Date()


     if (!itemStr || item.expiry < now.getTime()) {
      return <Navigate to="/login" />;
      
     
    }   
     

    const { Title } = Typography;  

    if(location.state){
       catName = location.state.catName;
       quesData = location.state.quesData;
       ansArray = location.state.ansArray;
    }

    
    return (
        <>

<MetaTags>
            <title>{catName} Online Quiz Answers  - PolyQuiz</title>
            <meta id="meta-description" name="description" 
             />     
</MetaTags>

        <Navbar /> 

        <Row className='test-head-div'>
<div className="head-div">
<div className='heading-timer'>
<Col>
        <div className="heading-container">
        <Title  className = "test-heading" level={4}> {catName} Online Test</Title>
        </div>
        </Col>
        </div>
        </div>
        </Row>

{/* <div className="container"> */}
<Row className="result-block" style={{marginTop:'1rem'}} gutter={16}>
    <Col>
    <div className="eight">
      <h1>Quiz Solutions</h1>
    </div>
    </Col>
  </Row>

  <Row className="result-block quiz-solutions">
  <Col span={24}>
          {quesData.map((ques,key) => {  
              return(
        <div id="console-div">
          <div id="ques-div-sol">
          {/* <div className="performance-card"> */}
              <table className="table">
    <thead className='ques-sol-heading'>
      <tr>
        <th id ="option-column" scope="col">Que. {key+1}</th>
        <th scope="col"><span class="ques-sol">{ques.Question}</span></th>
      </tr>
  
  
  {ques.Image1 &&
      <tr>
      <td></td>
        <td scope="col" class="img-column">
        <img 
        src={`https://epolylearning.com/QuestionImages/${ques.QuestionId}-1.png`}
        alt="new"
        />
          </td>    
      </tr>
  }
  
  {ques.Image2 &&
      <tr>
      <td></td>
        <td scope="col">
        <img 
        src={`https://epolylearning.com/QuestionImages/${ques.QuestionId}-2.png`}
        alt="new"
        />
          </td>    
      </tr>
  }
  
    </thead>
    <tbody>
      <tr>
        <th scope="row"><input type="radio" checked={ansArray[key] == ques.Option1} className="ans-radio" id = "optiona" value = {ques.Option1} disabled="disabled"  /></th>
         {ques.Option1 == ques.Answer ? <td>{ques.Option1}<span class="checkmark">
    <div class="checkmark_circle"></div>
    <div class="checkmark_stem"></div>
    <div class="checkmark_kick"></div>
</span></td> : <td>{ques.Option1}</td>} 
  
      </tr>
      <tr className='option-heading'>
        <th scope="row"><input type="radio" checked={ansArray[key] == ques.Option2} className="ans-radio" id = "optionb" value = {ques.Option2} disabled="disabled" /></th>
        {ques.Option2 == ques.Answer ? <td>{ques.Option2}<span class="checkmark">
    <div class="checkmark_circle"></div>
    <div class="checkmark_stem"></div>
    <div class="checkmark_kick"></div>
</span></td> : <td>{ques.Option2}</td>} 
        
      </tr>
      <tr>
        <th scope="row"><input type="radio" checked={ansArray[key] == ques.Option3} className="ans-radio" id = "optionc" value = {ques.Option3}  disabled="disabled" /></th>
        {ques.Option3 == ques.Answer ? <td>{ques.Option3}<span class="checkmark">
    <div class="checkmark_circle"></div>
    <div class="checkmark_stem"></div>
    <div class="checkmark_kick"></div>
</span></td> : <td>{ques.Option3}</td>} 
        
      </tr>
  
      <tr className='option-heading'>
        <th scope="row"><input type="radio" checked={ansArray[key] == ques.Option4} className="ans-radio" id = "optiond" value = {ques.Option4}  disabled="disabled" /></th>
        {ques.Option4 == ques.Answer ? <td>{ques.Option4}<span class="checkmark">
    <div class="checkmark_circle"></div>
    <div class="checkmark_stem"></div>
    <div class="checkmark_kick"></div>
</span></td> : <td>{ques.Option4}</td>}  
      </tr>

    </tbody>
  </table>
          {/* </div> */}
  
          </div>
          </div>





              )
})    
}
</Col>

</Row>

<div className='row result-block btn-container'>
        <div className="performance-card">
        <button onClick={() => navigate(-1)} className="btn btn-danger sub-btn">Go Back to Analysis</button>

<Link to={{
    pathname: '/', 
}}
className="btn btn-danger sub-btn">Go to Tests</Link>
        </div>
        </div>

  {/* </div> */}
  
          </>
      )
    }

export default TestSolutionPage
