import {React, useEffect, useState} from 'react';
import axios from 'axios';
import MetaTags from 'react-meta-tags';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import '../styles/userprogresspage.css';
import { Typography, Divider, Row, Col } from 'antd';
import BottomFooter from '../components/BottomFooter';

const UserProgressPage = () => {

    const [userTestData, setUserTestData] = useState([]);
    const navigate = useNavigate();
    var item ="";

    const {Title, Text} = Typography;

    const itemStr = localStorage.getItem('isLogin');
    item = JSON.parse(itemStr)
    const now = new Date()

   

    const getTestData = async () =>  {
        try {
           const json = JSON.stringify({ 
            userid: item.userid,    
        });
    
           const headers = { 
               'Content-Type': 'application/json'
           };
          
           const res = await axios.post('https://epolylearning.com/Services/polyquiz.svc/GetTestData',json,{headers}).then(response => {
                const testData = response.data.GetTestDataResult.UserScoreList;
               setUserTestData(testData);
           });
       } catch (error) {
        if (error.response){
            console.log(error.response);
            }else if(error.request){
                console.log(error.request);
            }else if(error.message){
                console.log(error.message);
            }
       } 
    }

    useEffect(() => {

      if (!itemStr || item.expiry < now.getTime()) {
        navigate('/login');
      }

        getTestData()
      },[])

    return (
        <>

<MetaTags>
            <title>User Progress Summary and Test Data - PolyQuiz</title>
            <meta id="meta-description" name="description" />
</MetaTags>

            <Navbar />

            <Row className='about-us-div'>

    
<Col>
<Typography>
       

       <div className='signup-heading'>
        <Title level={3}>Your Quiz History and Score</Title>
        </div>
        <Divider />
            <p className='progress-heading'>You can see below the details of latest tests attempted by you.</p>
            
            {userTestData.length>0 ?
            <div className='user-progress table-responsive hscroll'>
            <table scroll={{ x: 400 }} class="table table-hover table-striped progress-table">
  <thead className="table-dark">
    <tr>
      <th scope="col">S.no</th>
      <th scope="col">Quiz Name</th>
      <th scope="col">Total Questions</th>
      <th scope="col">Attempted Questions</th>
      <th scope="col">Your Score</th>
      <th scope="col">Rank</th>
      <th scope="col">Percentile</th>
    </tr>
  </thead>
  <tbody className='progress-table-body'>

  {
   userTestData.map((item, key) => {
     return(
         <>
    <tr>
      <th scope="row">{key+1}</th>
      <td>{item.TestName}</td>
      <td>{item.MaxScore}</td>
      <td>{item.Attempted}</td>
      <td>{item.Score}</td>
      <td>{item.Rank}</td>
      <td>{item.Percentile}</td>
    </tr>
    </>
     )})}
  </tbody>
</table>
             </div> : 
             <div className='progress-text'>No Test is attempted by you yet.</div>
}
            
           
            </Typography>
            </Col>
            </Row>
            <BottomFooter />
        </>
    )
}

export default UserProgressPage
