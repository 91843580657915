import { useState, useEffect } from 'react';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import ResponsiveAntMenu from 'responsive-ant-menu';
import { Layout, Menu, Typography} from 'antd';
import '../styles/navbar.css';
import logo from '../images/nav_logo.png';
import { LineChartOutlined, KeyOutlined, LogoutOutlined, UserOutlined} from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { MenuOutlined } from '@ant-design/icons';


const Navbar = () => {

const [isLogin, setIsLogin] = useState(false);
let navigate = useNavigate();
var item ="";
const itemStr = localStorage.getItem('isLogin');
item = JSON.parse(itemStr)
const now = new Date()

const { Header } = Layout;


useEffect(() => {
if (itemStr && item.expiry > now.getTime()) {
  setIsLogin(true);
}
}, []);

function Logout() {
  localStorage.removeItem('isLogin')
  navigate('/');
  window.location.reload(false)
}

function handleMenuClick(e) {
  
}

const menuLogin = (
  <Menu className='usr-btn' onClick={handleMenuClick}>
    <Menu.Item className='usr-btn' key="1" icon={<LineChartOutlined />}>
    <Link to="/check-user-progress">My Progress</Link>
    </Menu.Item>
    <Menu.Item className='usr-btn' key="2" icon={<KeyOutlined />}>
    <Link to="/auth/change-user-password">Change Password</Link>
    </Menu.Item>
    <Menu.Item key="3" className='usr-btn' icon={<LogoutOutlined />}>
    <span onClick={Logout}>Logout</span>
    </Menu.Item>
  </Menu>
);

  return (

<>


    <Header>
      <div className="logo">
          <img
          height='150px'
          width='160'
          alt=''
          loading='lazy'
          src={logo} />
      </div>
      
      <Menu theme="dark" mode="horizontal" overflowedIndicator= {<span style={{paddingLeft:"2rem"}}><MenuOutlined /></span>}>

        <Menu.Item key="1"><Link to="/">Home</Link></Menu.Item>

        <Menu.Item key="8"><Link to="/online-quiz-test/Aptitude">Aptitude Quizzes</Link></Menu.Item>

        <Menu.Item key="2"><Link to="/online-quiz-test/Verbal-Ability">Verbal Ability Quizzes</Link></Menu.Item>

        <Menu.Item key="3"><Link to="/online-quiz-test/Verbal-Reasoning">Verbal Reasoning Quizzes</Link></Menu.Item>

        <Menu.Item key="4"><Link to="/online-quiz-test/Civil-Engineering">Civil Engineering Quizzes</Link></Menu.Item>
        
        {(() => {

if (isLogin) {
return (<>

<Menu.Item className='login-signup-links marginer-link-double' key="5">

        <Dropdown.Button className='usr-btn nav-login' overlay={menuLogin}  size='large' icon={<UserOutlined />}>
        {(item.username.substring(0,14))}
    </Dropdown.Button>
    </Menu.Item> 
</>)

} else {
  return (
    <>
        <Menu.Item className='login-signup-links marginer-link' key="6">
        <Button className='nav-login'><Link to='/login'>Login</Link></Button>
        </Menu.Item>

        <Menu.Item className='login-signup-links' key="7">
        <Button className='nav-login'><Link to='/register'>Sign Up</Link></Button>
        </Menu.Item>
        </>)}

})()}
        
      </Menu>
        
    </Header>


  
   
</>
    
  )
}

export default Navbar