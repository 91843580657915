import {React, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import '../styles/lefttestconsole.css';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const LeftTestConsole = ({setQues, ansArray, catName, quesData, type, testId, curQues, visitedArr, MyTimer, time, timeExpired, rankArray}) => {

    let arr = Array.apply(null, {length: quesData.length}).map(Number.call, Number);
    const navigate = useNavigate();

    const { confirm } = Modal;

    const ConfirmPopUp = () => {
        return (
          <>
           <table class="table table-striped">
        
        <tbody>
          <tr className='modal-row'>
            <th scope="row">Time Left</th>
            <td><MyTimer expiryTimestamp={time} /></td>
            
          </tr>
          <tr className='modal-row'>
            <th scope="row">Attempted Questions</th>
            <td style={{textAlign: 'center', color: '#73a24e'}}>{ansArray.filter(ans => ans != undefined).length}</td>
            
          </tr>
          <tr className='modal-row'>
            <th scope="row">Unattempted Questions</th>
            <td style={{textAlign: 'center', color: '#d03f2f'}}>{quesData.length - ansArray.filter(ans => ans != undefined).length}</td>
            
          </tr>
        </tbody>
      </table>
          </>
        )
    
      }

    const submitTest = () => {

        navigate(
                
            `/online-quiz-test-result/${catName.replaceAll(" ","-")}`, {
                state:{    ansArray: ansArray, catName: catName, quesData: quesData, type, testId: testId, rankArray: rankArray}
              }
            
        )   

    }  

    function showPromiseConfirm() {
        confirm({
          cancelText: 'Resume Test',
          okText: 'Finish Test',
          title: 'Are you sure to submit the Test?',
          icon: <ExclamationCircleOutlined />,
          content: <ConfirmPopUp />,
          onOk() {
            return new Promise((resolve, reject) => {
             submitTest();
             reject()
            }).catch(() => console.log('Oops errors!'));
          },
          onCancel() {},
        });
      }

    useEffect(() => {
        if(timeExpired !== 0){

          submitTest();
        //  navigate({
                
        //         pathname:  `/online-quiz-test-result/${catName.replaceAll(" ","-")}`,
        //         state: {ansArray: ansArray, catName: catName, quesData: quesData, type, testId: testId, rankArray: rankArray},
                
        //     })          
        } 
      }, [timeExpired]);

    return (    
        <>

<div className='test-summary'>
            <ul className='test-summary-list'>
        <li><button className="nav-btn-checked indicator-btn">{ansArray.filter(ans => ans != undefined).length}</button><span class="indicator-text">Answered</span></li>
        {visitedArr.length != 30 ?  <li><button className="nav-btn-visited indicator-btn">{visitedArr.filter(ans => ans != undefined).length+1 - ansArray.filter(ans => ans != undefined).length }</button><span class="indicator-text">Not Answered</span></li>:
       <li><button className="nav-btn-visited indicator-btn">{visitedArr.filter(ans => ans != undefined).length - ansArray.filter(ans => ans != undefined).length}</button><span class="indicator-text">Not Answered</span></li>
       }
       {visitedArr.length != 30 ? <li><button className="nav-btn indicator-btn">{quesData.length - visitedArr.filter(ans => ans != undefined).length-1}</button><span class="indicator-text">Not Visited</span></li>:
       <li><button className="nav-btn indicator-btn">{quesData.length - visitedArr.filter(ans => ans != undefined).length}</button><span class="indicator-text">Not Visited</span></li>
       }       
            </ul>
</div>


      <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
       <div class="btn-group mr-2" role="group" aria-label="First group">

               {arr.map(item => {
                   console.log(item);

                 if(curQues == item){
                    return <button onClick = {() => {setQues(item)}} type="button" className="nav-btn-current">{item+1}</button>
                   }
                 else if(ansArray[item]){
                    return <button onClick = {() => {setQues(item)}} type="button" className="nav-btn-checked">{item+1}</button>
                   }
                 else if(visitedArr[item] == item){
                    return <button onClick = {() => {setQues(item)}} type="button" className="nav-btn-visited">{item+1}</button>
                   }
                  else
                {return <button onClick = {() => {setQues(item)}} type="button" className="nav-btn">{item+1}</button>}
            })}

        </div>
        </div>

<div id="submit-div">
        <button onClick={showPromiseConfirm} type="button" className="sub-btn">
        Submit Test
</button>
</div>

        </>
    )
}

export default LeftTestConsole
