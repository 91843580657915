import React from 'react';
import { useState, useEffect } from "react";
import { useNavigate , useLocation} from "react-router-dom";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import '../styles/loginpage.css';
import Logo from '../images/logo_transparent.png'
import Navbar from '../components/Navbar';
import BottomFooter from '../components/BottomFooter';
import {Link} from 'react-router-dom';
import { Form, Input, Button, Typography } from 'antd';

const ResetPasswordPage = (props) => {

    const [changepasserror, setChangePassError] = useState(false);
    const [changepassmessage, setChangePassMessage] = useState(false);
    const [passResetVerification, setPassResetVerification] = useState(false);

    let navigate = useNavigate();
    let location = useLocation();
    const itemStr = localStorage.getItem('isLogin');
    const item = JSON.parse(itemStr)
    const now = new Date()

    const [form] = Form.useForm();
    const { Title } = Typography;

    if(itemStr && item.expiry > now.getTime()){
      navigate('/');
      window.location.reload(false);
    }
    let { code } = useParams(); 
    
    const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      };

      const tailFormItemLayout = {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 8,
          },
        },
      };
    
    const VerifyUserAccount = () => {
        try {
           const json = JSON.stringify({resetcode : code});
           const headers = { 
               'Content-Type': 'application/json'
           };
          
           const res = axios.post('https://epolylearning.com/Services/polyquiz.svc/PasswordResetRequest', json, {headers}).then(response => {
               const resMsg = response.data;         
                 if(resMsg.PasswordResetRequestResult == "Password reset request verification succesfull."){
                   setPassResetVerification(true);
                 }
                 else if(resMsg.PasswordResetRequestResult == "Invalid Request."){
                    setChangePassError(true);
                 } 
           });
       } catch (error) {
           console.log(error);
       } 
      }

      useEffect(() => {
        VerifyUserAccount()
      },[])


  function onFinish(data) {
    setChangePassError(false);
    setChangePassMessage(false);
    try {
       const json = JSON.stringify({passcode: code , newpass:data.newpassword });
       const headers = { 
           'Content-Type': 'application/json'
       };
      
       const res = axios.post('https://epolylearning.com/Services/polyquiz.svc/ResetUserPassword', json, {headers}).then(response => {
           const userData = response.data;
             if(userData.ResetUserPasswordResult == "Password changed successfully."){
                form.resetFields();
                setChangePassMessage(true);
             }
             else if(userData.ResetUserPasswordResult == "Invalid Request" ){
                form.resetFields();
                setChangePassError(true);
             }
       });
   } catch (error) {
       console.log(error);
   } 
  }

    return (
        <>
        <Navbar />

        {passResetVerification ? 
        <div className='BoxContainer'>
          <div className='FormContainer change-pass-div'>

          <div className='signup-heading'>
  <img width="150px" height="100px" src={Logo} alt="Test and Rank logo" />
  </div>

    <div className='login-heading'>
    <Title className='signup-heading' level={4}>RESET PASSWORD</Title>
  </div>

        <div className="Login">

        <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={{
        residence: ['zhejiang', 'hangzhou', 'xihu'],
        prefix: '86',
      }}
      scrollToFirstError
    >

      <Form.Item
        name="newpassword"
        label="New Password"
        type = "password"
        rules={[
          {
            required: true,
            message: 'Please input your new password!',
          },
          {
              min: 8,
              message: 'Minimum password length is 8 characters.'
          },
          {
              max:16,
              message: 'Maximum password length is 16 characters.'
          }
        ]}
        hasFeedback
      >
        <Input type="password"/>
      </Form.Item>

      <Form.Item
      className='confirm-pass'
        name="confirm"
        label="Confirm New Password"
        dependencies={['newpassword']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newpassword') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The two passwords that you entered do not match!'));
            },
          }),
        ]}
      >
        <Input type="password"/>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          Reset Password
        </Button>
      </Form.Item>

        </Form> 
        
          </div>

{changepassmessage ?<div className="invalid-user">Password changed successfully. <Link to="/login">Click here</Link> to Log in and continue.</div> : ''}
     
          </div>
        </div>
  :   
"" }
{changepasserror ?<div className="invalid-user">This is Invalid request. Please start a new request.</div> : ''}
       
       <BottomFooter />
        </>
      );
};

export default ResetPasswordPage;
