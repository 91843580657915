import {React, useEffect, useState} from 'react';
import axios from 'axios';
import { Layout, Row, Col } from 'antd';
import MetaTags from 'react-meta-tags';
import Navbar from '../components/Navbar';
import TopContainer from '../components/TopContainer';
import CatCardContainer from '../components/CatCardContainer';
import BottomFooter from '../components/BottomFooter';
import HomePageSider from '../components/HomePageSider';
import '../styles/homepage.css'

const HomePage = () => {
    const { Sider, Content } = Layout;


    const [allCategories, setAllCategories] = useState({}); 
    const [allSubCategories, setAllSubCategories] = useState({});    
    
    const getAllCategories = async () =>  {
         try {
            const response = await axios.get('https://epolylearning.com/Services/polyquiz.svc/allcategories');
            const catData = response.data.CategoryList;
            setAllCategories(catData);
        } catch (error) {
            console.log(error);
        } 
    }

    const getAllSubCategories = async () =>  {
      try {
         const response = await axios.get('https://epolylearning.com/Services/polyquiz.svc/allsubcategories');
         const subCatData = response.data.SubCategoryList;
         setAllSubCategories(subCatData);
     } catch (error) {
         console.log(error);
     } 
 }
    
    useEffect(() => {
       getAllCategories();  
       getAllSubCategories();
    }, []);


  return (
    <>


<MetaTags>
            <title>PolyQuiz - Free Online Quiz Tests for everyone.</title>
            <meta id="meta-description" name="description" 
            content="Access free Online Quizzes and Tests for competitive exams on
            variety of topics like Aptitude online quiz, Logical Reasoning online quiz, General Knowledge online quiz
            Biology online quiz, Chemistry quiz, Data Interpretation quiz and many more." />
    
</MetaTags>
    
    <Layout>
        
   
   <Navbar />
 
   <Content><TopContainer /></Content>
   <Layout className='sidebar'>
     <Row><Col  xs={0} sm={0} md={4} lg={4}>
        <Sider collapsedWidth={0}><HomePageSider allCategories = {allCategories} allSubCategories = {allSubCategories} /></Sider>
        </Col></Row>
        <Content id='home-content'> <CatCardContainer allCategories = {allCategories} /></Content>
      </Layout>
      <BottomFooter />

   </Layout>  
    
    </>
  )
}

export default HomePage