import {React, useState, useEffect} from 'react';
import LeftTestConsole from './LeftTestConsole';
import MiddleTestConsole from './MiddleTestConsole';
import RightTestConsole from './RightTestConsole';
import '..//styles/testconsole.css';
import {Row , Col} from 'antd';
import GoToTop from "../components/GoToTop";

const TestConsole = ({quesData, catName, type, testId, MyTimer, time, timeExpired, rankArray}) => {

    const [curQues, SetcurQues] = useState(0);
    const [ansArray, SetansArray] = useState([]);
    const[visitedArr, SetVisitedArray] = useState([]);

    let vArr = [...visitedArr];

    const setAnswer = (e) => {
        let newArr = [...ansArray];
        newArr[curQues] = e;
        SetansArray(newArr);   
    }

    const nextQues = () => {
        if (!(curQues == (quesData.length-1)))
        {
        vArr[curQues] = curQues;
        SetVisitedArray(vArr);

        SetcurQues(curQues + 1);
        var radiobtna = document.getElementById('optiona');
        if(typeof ansArray[curQues+1] != 'undefined' && ansArray[curQues+1] == quesData[curQues+1].Option1){
            radiobtna.checked = true;
        }
        else{
            radiobtna.checked = false;
        }
        var radiobtnb = document.getElementById('optionb');
        if(typeof ansArray[curQues+1] != 'undefined' && ansArray[curQues+1] == quesData[curQues+1].Option2){
            radiobtnb.checked = true;
        }
        else{
            radiobtnb.checked = false;
        }
        var radiobtnc = document.getElementById('optionc');
        if(typeof ansArray[curQues+1] != 'undefined' && ansArray[curQues+1] == quesData[curQues+1].Option3){
            radiobtnc.checked = true;
        }
        else{
            radiobtnc.checked = false;
        }
        var radiobtnd = document.getElementById('optiond');
        if(typeof ansArray[curQues+1] != 'undefined' && ansArray[curQues+1] == quesData[curQues+1].Option4){
            radiobtnd.checked = true;
        }
        else{
            radiobtnd.checked = false;
        }
        }
      }
   
      const preQues = () => { 
        if(!(curQues == 0)){

            vArr[curQues] = curQues;
            SetVisitedArray(vArr);
            SetcurQues(curQues - 1);
            var radiobtna = document.getElementById('optiona');
            if(typeof ansArray[curQues-1] != 'undefined' && ansArray[curQues-1] == quesData[curQues-1].Option1){
                radiobtna.checked = true;
             }
        else{
            radiobtna.checked = false;
        }
        var radiobtnb = document.getElementById('optionb');
        if(typeof ansArray[curQues-1] != 'undefined' && ansArray[curQues-1] == quesData[curQues-1].Option2){
            radiobtnb.checked = true;
        }
        else{
            radiobtnb.checked = false;
        }
        var radiobtnc = document.getElementById('optionc');
        if(typeof ansArray[curQues-1] != 'undefined' && ansArray[curQues-1] == quesData[curQues-1].Option3){
            radiobtnc.checked = true;
        }
        else{
            radiobtnc.checked = false;
        }
        var radiobtnd = document.getElementById('optiond');
        if(typeof ansArray[curQues-1] != 'undefined' && ansArray[curQues-1] == quesData[curQues-1].Option4){
            radiobtnd.checked = true;
        }
        else{
            radiobtnd.checked = false;
        }

          }
     }

     const clearResponse = () => {

        var radiobtna = document.getElementById('optiona');
        var radiobtnb = document.getElementById('optionb');
        var radiobtnc = document.getElementById('optionc');
        var radiobtnd = document.getElementById('optiond');

        radiobtna.checked = false;
        radiobtnb.checked = false;
        radiobtnc.checked = false;
        radiobtnd.checked = false;

        let newArr = [...ansArray];
        newArr[curQues] = null;
        SetansArray(newArr);
     }

     const setQues = (index) => {

        vArr[curQues] = curQues;
        SetVisitedArray(vArr);
        
        SetcurQues(index);
        var radiobtna = document.getElementById('optiona');
        if(typeof ansArray[index] != 'undefined' && ansArray[index] == quesData[index].Option1){
            radiobtna.checked = true;
        }
        else{
            radiobtna.checked = false;
        }
        
        var radiobtnb = document.getElementById('optionb');
        if(typeof ansArray[index] != 'undefined' && ansArray[index] == quesData[index].Option2){
            radiobtnb.checked = true;
        }
        else{
            radiobtnb.checked = false;
        }
        var radiobtnc = document.getElementById('optionc');
        if(typeof ansArray[index] != 'undefined' && ansArray[index] == quesData[index].Option3){
            radiobtnc.checked = true;
        }
        else{
            radiobtnc.checked = false;
        }
        var radiobtnd = document.getElementById('optiond');
        if(typeof ansArray[index] != 'undefined' && ansArray[index] == quesData[index].Option4){
            radiobtnd.checked = true;
        }
        else{
            radiobtnd.checked = false;
        }

     }


     useEffect(() => {

        window.scrollTo(0, 0);
        
      }, []);

    return(
        <>
         
         {/* <div id ="mainconsole"> */}
         <GoToTop />
        <Row>
  <Col xs={24} sm={24} lg={4}>
             <div id="left-console">
             <LeftTestConsole setQues = {setQues} ansArray ={ansArray} catName = {catName} quesData = {quesData} type= {type} testId = {testId} curQues = {curQues} visitedArr = {visitedArr} MyTimer = {MyTimer} time = {time} timeExpired = {timeExpired} rankArray = {rankArray}/>
             </div>
             </Col>

            
             <Col xs={24} sm={24} lg={16}>
             <div id="middle-console">
             <MiddleTestConsole questions = {quesData} curQues = {curQues} nextQues = {nextQues} preQues = {preQues} clearResponse = {clearResponse} setAnswer ={setAnswer} 
             ansArray ={ansArray}/>
             </div>
             </Col>
             
             
             <Col xs={24} sm={24} lg={4}>
             <div>
             <RightTestConsole />
             </div>
             </Col>

         {/* </div>  */}
         </Row>
         </>
    )
}

export default TestConsole
